<template>
  <html>
    <div class="app">
      <MenuPrincipal/>
      <div class="container-fluid">
        <body>
          <Declaracion />
        </body>
      </div>
      <Footer/>
      <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    </div>
  </html>
</template>

<script>
import MenuPrincipal from "@/components/Cuenta/MenuPrincipal.vue"
import Declaracion from "@/components/Cuenta/Prestamo/Declaracion.vue"
import Footer from '@/components/Footer.vue'
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {
  data() {
    return {
      errors: [],
      apiToken: null,
    };
  },
  name: 'DeclaracionPrincipal',
  components: {
    MenuPrincipal,
    Declaracion,
    Footer,
  },
   beforeMount() {
    this.checkLogin();
  },
  methods:{
    checkLogin(){
      var user = getTokenDecoden();
      if(user != null){
        if(user.obj['role'] == "administrator"){
          // window.location.href = "/admin/principal";
           this.$router.push({path:"/admin/principal"});
        } else if(user.obj['role'] == "loan_analyst"){
          // window.location.href = "/analyst/principal";
           this.$router.push({path:"/analyst/principal"});
        }
      }
      else{
        // window.location.href = "/"
        this.$router.push({path:"/"});
      }
    }
  }
}
</script>

<style scoped>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

button {outline: none !important;}
</style>