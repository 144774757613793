<template>
<div class=" mx-auto">
  <div class="row">
      <MenuLateral/>
      <div style="text-align:center;padding:60px 10px 10px 10px;">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-start-5 col-end-9">
            <p style="font-family:Roboto;font-weight:700;color:#223635;font-size:20px;">Propietario Real</p>
          </div>
          <div class="col-start-5 col-end-9">
            <StepProgress style="" :steps="mySteps" :current-step="currentStep" active-color="#bfd243" passive-color="#223635" icon-class="fa fa-check"></StepProgress>
          </div>
        </div>
        <p style="font-family:Roboto;font-weight:500;color:#223635;font-size:15px;">Llena los campos requeridos conforme se le sean solicitados</p>
      </div>
      <div class="col col-sm-9">
        <Declaracion v-on:event-tab="changeTab" step="1" tab="datos-tercero-principal" :showBackButton="showBackButton" action="reedirect" url="/cuenta/prestamo/principal/5/cargo-publico/1" v-if="showTab == 'declaracion'" />
        <DatosTerceroPrincipal v-on:event-tab="changeTab" v-on:event-tab-back="goToBack" step="2" stepBack="0" tab="datos-tercero-direccion" tabBack="declaracion" v-if="showTab == 'datos-tercero-principal'" />
        <DatosTerceroDireccion v-on:event-tab="changeTab" v-on:event-tab-back="goToBack" step="3" stepBack="1" tab="datos-tercero-contacto" tabBack="datos-tercero-principal" v-if="showTab == 'datos-tercero-direccion'" />
        <DatosTerceroContacto v-on:event-tab="changeTab" v-on:event-tab-back="goToBack" step="4" stepBack="2" tab="cargo-publico-familiar" tabBack="datos-tercero-direccion" v-if="showTab == 'datos-tercero-contacto'" />
        <CargoPublicoFamiliar v-on:event-tab="changeTab" v-on:event-tab-back="goToBack" step="5" stepBack="3" tab="cargo-publico" tabBack="datos-tercero-contacto" v-if="showTab == 'cargo-publico-familiar'" />
        <CargoPublico v-on:event-tab="changeTab" v-on:event-tab-back="goToBack" step="6" stepBack="4" tab="''" tabBack="cargo-publico-familiar" :title="''" action="reedirect" url="/cuenta/prestamo/proveedor-recursos/-1/-1/1" v-if="showTab == 'cargo-publico'" />
      </div>
  </div>
  <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
</div>
</template>

<script>
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Declaracion from "@/components/Cuenta/Prestamo/Forms/Declaracion.vue"
import DatosTerceroPrincipal from "@/components/Cuenta/Prestamo/Forms/DatosTerceroPrincipal.vue"
import DatosTerceroDireccion from "@/components/Cuenta/Prestamo/Forms/DatosTerceroDireccion.vue"
import DatosTerceroContacto from "@/components/Cuenta/Prestamo/Forms/DatosTerceroContacto.vue"
import CargoPublicoFamiliar from "@/components/Cuenta/Prestamo/Forms/CargoPublicoFamiliar.vue"
import CargoPublico from "@/components/Cuenta/Prestamo/Forms/CargoPublico.vue"
import StepProgress from 'vue-step-progress';
import 'vue-step-progress/dist/main.css';
import Swal from "sweetalert2";
import moment from "moment";
import { saveRequest,getAdmissionRequest } from "@/api/user";

export default {
  data() {
    return {
      showTab: "declaracion",
      showBackButton: "0",
      titleCargoPublico: "",
      mySteps: ['', '', '', '', '', ''],
      currentStep: 0,
    };
  },
  name: "DeclaracionComponent", //CambiarContraseña
  components: {
    MenuLateral,
    Declaracion,
    DatosTerceroPrincipal,
    DatosTerceroDireccion,
    DatosTerceroContacto,
    CargoPublicoFamiliar,
    CargoPublico,
    StepProgress,
    Loading
  },
  props: {
  },
  async mounted() {},
  async beforeMount() {
    if (this.$route.params.showBackButton != undefined) {
      this.showBackButton = this.$route.params.showBackButton;
    }
    if (this.$route.params.step != '-1' && this.$route.params.form != '-1') {
      this.showTab = this.$route.params.form;
      this.currentStep = this.$route.params.step;
    }
  },
  methods: {
    changeTab(event) {
      if (event.url != "") {
        alert(event.url);
        // window.location.href = '/cuenta/prestamo/declaracion';
        this.$router.push({path:"/cuenta/prestamo/declaracion"});
      } else {
        this.showTab = event.tab;
        this.currentStep = event.step;
        this.titleCargoPublico = event.title;
      }
    },
    goToBack(event) {
      if (event.url != "") {
        // window.location.href = '/cuenta/prestamo/declaracion';
        this.$router.push({path:"/cuenta/prestamo/declaracion"});
      } else {
        this.showTab = event.tabBack;
        this.currentStep = event.stepBack;
        this.titleCargoPublico = event.title;
      }
    }
  },
};
</script>

<style>
.mycontent-left {
  border-right: 1px dashed #333;
  height: 250px;
}
#panel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.step-progress__bar {
  margin-bottom: 20px !important;
  height: 0px !important
}

.step-progress__wrapper-after, .step-progress__wrapper-before {
  height: 3px !important;
}

.step-progress__step {
  --activeBorder: 2px !important;
  --passiveBorder: 2px !important;
}
.step-progress__step span {
  font-size: 12px;
}

.step-progress__step:after {
  width: 30px !important;
  height: 30px !important;
}

</style>